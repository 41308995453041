<template>
  <div>
    <sidebar :menuItems="menuItems" />
    <main class="mb-0 mr-0">
      <div class="container-fluid pr-0 pt-4">
        <transition name="zoom" mode="out-in">
          <router-view :key="$route.path" />
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { managementMixin } from '@/mixins/managementMixin'
import { menuItems } from '@/constants/menu'
import { pusherMixin } from '@/mixins/pusherMixin'
import Sidebar from '@/containers/Sidebar'
export default {
  components: {
    sidebar: Sidebar
  },
  data() {
    return {
      menuItems,
      selectedMenu: '',
      teams: []
    }
  },
  mixins: [managementMixin, pusherMixin],
  methods: {
    ...mapActions(['fetchOpenTeamWeek', 'fetchVmbObservationTypes']),
    ...mapMutations(['upsertDashboardItems']),
    async getTeams() {
      await this.getSimulationTeams(this.currentSimulation.id)
    },
    async initiateVmbListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`vmb.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'vmb',
        (data) => {
          this.upsertDashboardItems(data.data)
        },
        channel.unbind()
      )
    }
  },
  computed: {
    ...mapGetters(['currentSimulation', 'openTeamWeek'])
  },
  mounted() {
    this.fetchVmbObservationTypes()
  },
  watch: {
    currentSimulation: {
      async handler(newValue) {
        if (newValue) {
          await this.getTeams()
          // await this.fetchOpenTeamWeek(newValue.id)
        }
      },
      immediate: true
    },
    openTeamWeek: {
      async handler(newValue) {
        if (newValue) {
          await this.initiateVmbListener()
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.leaveChannels()
  }
}
</script>

<style lang="scss" scoped>
.dashboard-main {
  margin-left: 50px;
}
.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
